import { MetadataStore } from 'breeze-client';

import { Account } from './account';
import { AccountAddress } from './account-address';
import { AccountAdmin } from './account-admin';
import { AccountAdminGroup } from './account-admin-group';
import { AccountAdminGroupMap } from './account-admin-group-map';
import { AccountBlanketPurchaseOrder } from './account-blanket-purchase-order';
import { AccountBlanketPurchaseOrderLog } from './account-blanket-purchase-order-log';
import { AccountBudget } from './account-budget';
import { AccountImage } from './account-image';
import { AccountImport } from './account-import';
import { AccountImportCodeOption } from './account-import-code-option';
import { AccountIssuance } from './account-issuance';
import { AccountIssuanceUserGroupMap } from './account-issuance-user-group-map';
import { AccountIssuanceUserLog } from './account-issuance-user-log';
import { AccountUser } from './account-user';
import { ActiveStatus } from './active-status';
import { Addon } from './addon';
import { AddonImage } from './addon-image';
import { AllowanceProrationRule } from './allowance-proration-rule';
import { ApiCombinePair } from './api-combine-pair';
import { ApprovalTree } from './approval-tree';
import { ApprovalTreeAdminGroup } from './approval-tree-admin-group';
import { ApprovalTreeAdminGroupLog } from './approval-tree-admin-group-log';
import { ApprovalTreeUserGroup } from './approval-tree-user-group';
import { BudgetProductTypeTag } from './budget-product-type-tag';
import { BudgetProductTypeTagMap } from './budget-product-type-tag-map';
import { ChangeLog } from './change-log';
import { DocSubmitType } from './doc-submit-type';
import { NarrativeUserGroupDto } from './narrative-user-group-dto';
import { DurationUnitOfMeasure } from './duration-unit-of-measure';
import { Feature } from './feature';
import { FeatureChoice } from './feature-choice';
import { FeatureDataType } from './feature-data-type';
import { Gender } from './gender';
import { GlobalAdmin } from './global-admin';
import { HelpItem } from './help-item';
import { InAppNotification } from './in-app-notification';
import { Invoice } from './invoice';
import { InvoiceDetail } from './invoice-detail';
import { JobOrder } from './job-order';
import { JobOrderBudgetLog } from './job-order-budget-log';
import { JobOrderDetail } from './job-order-detail';
import { JobOrderDetailAddon } from './job-order-detail-addon';
import { JobOrderHistDetail } from './job-order-hist-detail';
import { JobOrderHistDetailAddon } from './job-order-hist-detail-addon';
import { JobOrderHistDetailFeature } from './job-order-hist-detail-feature';
import { JobOrderHistDetailReceipt } from './job-order-hist-detail-receipt';
import { JobOrderNote } from './job-order-note';
import { JobOrderOveragePayment } from './job-order-overage-payment';
import { JobOrderOveragePaymentStatus } from './job-order-overage-payment-status';
import { JobOrderStatus } from './job-order-status';
import { JobOrderType } from './job-order-type';
import { LogType } from './log-type';
import { Manifest } from './manifest';
import { Manufacturer } from './manufacturer';
import { ManufacturerImage } from './manufacturer-image';
import { NextProgramAllowanceRule } from './next-program-allowance-rule';
import { NotificationEvent } from './notification-event';
import { NotificationEventAccountMap } from './notification-event-account-map';
import { NotificationQuery } from './notification-query';
import { NotificationStatus } from './notification-status';
import { NotificationSubmission } from './notification-submission';
import { NotificationTemplate } from './notification-template';
import { NotificationType } from './notification-type';
import { OrderPaidByType } from './order-paid-by-type';
import { PricedAddon } from './priced-addon';
import { PricedProductType } from './priced-product-type';
import { PricedProductTypeFeatureChoice } from './priced-product-type-feature-choice';
import { Product } from './product';
import { ProductFeatureChoice } from './product-feature-choice';
import { ProductType } from './product-type';
import { ProductTypeConfig } from './product-type-config';
import { ProductTypeConfigAddon } from './product-type-config-addon';
import { ProductTypeConfigProduct } from './product-type-config-product';
import { ProductTypeFeature } from './product-type-feature';
import { ProductTypeImage } from './product-type-image';
import { ProductTypeTag } from './product-type-tag';
import { ProductTypeTagMap } from './product-type-tag-map';
import { ProductTypeVersion } from './product-type-version';
import { Program } from './program';
import { ProgramAccountIssuanceMap } from './program-account-issuance-map';
import { ProgramAllowance } from './program-allowance';
import { ProgramAllowanceAddonException } from './program-allowance-addon-exception';
import { ProgramAllowanceFeatureException } from './program-allowance-feature-exception';
import { ProgramAllowanceUserGroupMap } from './program-allowance-user-group-map';
import { ProgramAllowanceUserLog } from './program-allowance-user-log';
import { ProgramApplicabilityType } from './program-applicability-type';
import { ProgramBudget } from './program-budget';
import { ProgramByAccountUserSummary } from './program-by-account-user-summary';
import { ProgramIssuance } from './program-issuance';
import { ProgramIssuanceUserGroupMap } from './program-issuance-user-group-map';
import { ProgramIssuanceUserLog } from './program-issuance-user-log';
import { ProgramProductCategoryTag } from './program-product-category-tag';
import { ProgramProductCategoryTagMap } from './program-product-category-tag-map';
import { ProgramProductTypeConfig } from './program-product-type-config';
import { ProgramProductTypeTag } from './program-product-type-tag';
import { ProgramProductTypeTagMap } from './program-product-type-tag-map';
import { ProgramRapidTemplate } from './program-rapid-template';
import { ProgramRapidTemplateProductTypeConfig } from './program-rapid-template-product-type-config';
import { ProgramStartDateType } from './program-start-date-type';
import { ProgramSupplier } from './program-supplier';
import { ProgramUserGroup } from './program-user-group';
import { ProgramUserGroupBudget } from './program-user-group-budget';
import { ProgramUserGroupExclusion } from './program-user-group-exclusion';
import { ProgramUserGroupMap } from './program-user-group-map';
import { ProgramView } from './program-view';
import { ProgramViewProductTypeConfig } from './program-view-product-type-config';
import { ProgramViolationRule } from './program-violation-rule';
import { ProximityArea } from './proximity-area';
import { ProximityPref } from './proximity-pref';
import { ProximityRight } from './proximity-right';
import { ProximityUser } from './proximity-user';
import { ProximityUserRight } from './proximity-user-right';
import { ReturnCreditType } from './return-credit-type';
import { ReturnPolicy } from './return-policy';
import { ReturnReason } from './return-reason';
import { ReturnReasonType } from './return-reason-type';
import { ReturnRequest } from './return-request';
import { ReturnRequestDetail } from './return-request-detail';
import { ReturnRequestDetailStatus } from './return-request-detail-status';
import { ReturnRequestNote } from './return-request-note';
import { ReturnRequestStatus } from './return-request-status';
import { ShipFrequency } from './ship-frequency';
import { Shipment } from './shipment';
import { ShipmentBox } from './shipment-box';
import { ShippingUserGroup } from './shipping-user-group';
import { ShippingUserGroupAddressMap } from './shipping-user-group-address-map';
import { ShippingUserGroupMap } from './shipping-user-group-map';
import { SmsTemplate } from './sms-template';
import { StockCondition } from './stock-condition';
import { Supplier } from './supplier';
import { SupplierAccount } from './supplier-account';
import { SupplierAdmin } from './supplier-admin';
import { SupplierCategory } from './supplier-category';
import { SupplierCategoryMap } from './supplier-category-map';
import { SupplierImage } from './supplier-image';
import { SupplierManifestMap } from './supplier-manifest-map';
import { SupplierSubmission } from './supplier-submission';
import { SupplierSubmissionStatus } from './supplier-submission-status';

export class ProximityRegistrationHelper {

    static register(metadataStore: MetadataStore) {
        metadataStore.registerEntityTypeCtor('Account', Account);
        metadataStore.registerEntityTypeCtor('AccountAddress', AccountAddress);
        metadataStore.registerEntityTypeCtor('AccountAdmin', AccountAdmin);
        metadataStore.registerEntityTypeCtor('AccountAdminGroup', AccountAdminGroup);
        metadataStore.registerEntityTypeCtor('AccountAdminGroupMap', AccountAdminGroupMap);
        metadataStore.registerEntityTypeCtor('AccountBlanketPurchaseOrder', AccountBlanketPurchaseOrder);
        metadataStore.registerEntityTypeCtor('AccountBlanketPurchaseOrderLog', AccountBlanketPurchaseOrderLog);
        metadataStore.registerEntityTypeCtor('AccountBudget', AccountBudget);
        metadataStore.registerEntityTypeCtor('AccountImage', AccountImage);
        metadataStore.registerEntityTypeCtor('AccountImport', AccountImport);
        metadataStore.registerEntityTypeCtor('AccountImportCodeOption', AccountImportCodeOption);
        metadataStore.registerEntityTypeCtor('AccountIssuance', AccountIssuance);
        metadataStore.registerEntityTypeCtor('AccountIssuanceUserGroupMap', AccountIssuanceUserGroupMap);
        metadataStore.registerEntityTypeCtor('AccountIssuanceUserLog', AccountIssuanceUserLog);
        metadataStore.registerEntityTypeCtor('AccountUser', AccountUser);
        metadataStore.registerEntityTypeCtor('ActiveStatus', ActiveStatus);
        metadataStore.registerEntityTypeCtor('Addon', Addon);
        metadataStore.registerEntityTypeCtor('AddonImage', AddonImage);
        metadataStore.registerEntityTypeCtor('AllowanceProrationRule', AllowanceProrationRule);
        metadataStore.registerEntityTypeCtor('ApiCombinePair', ApiCombinePair);
        metadataStore.registerEntityTypeCtor('ApprovalTree', ApprovalTree);
        metadataStore.registerEntityTypeCtor('ApprovalTreeAdminGroup', ApprovalTreeAdminGroup);
        metadataStore.registerEntityTypeCtor('ApprovalTreeAdminGroupLog', ApprovalTreeAdminGroupLog);
        metadataStore.registerEntityTypeCtor('ApprovalTreeUserGroup', ApprovalTreeUserGroup);
        metadataStore.registerEntityTypeCtor('BudgetProductTypeTag', BudgetProductTypeTag);
        metadataStore.registerEntityTypeCtor('BudgetProductTypeTagMap', BudgetProductTypeTagMap);
        metadataStore.registerEntityTypeCtor('ChangeLog', ChangeLog);
        metadataStore.registerEntityTypeCtor('DocSubmitType', DocSubmitType);
        metadataStore.registerEntityTypeCtor('NarrativeUserGroupDto', NarrativeUserGroupDto);
        metadataStore.registerEntityTypeCtor('DurationUnitOfMeasure', DurationUnitOfMeasure);
        metadataStore.registerEntityTypeCtor('Feature', Feature);
        metadataStore.registerEntityTypeCtor('FeatureChoice', FeatureChoice);
        metadataStore.registerEntityTypeCtor('FeatureDataType', FeatureDataType);
        metadataStore.registerEntityTypeCtor('Gender', Gender);
        metadataStore.registerEntityTypeCtor('GlobalAdmin', GlobalAdmin);
        metadataStore.registerEntityTypeCtor('HelpItem', HelpItem);
        metadataStore.registerEntityTypeCtor('InAppNotification', InAppNotification);
        metadataStore.registerEntityTypeCtor('Invoice', Invoice);
        metadataStore.registerEntityTypeCtor('InvoiceDetail', InvoiceDetail);
        metadataStore.registerEntityTypeCtor('JobOrder', JobOrder);
        metadataStore.registerEntityTypeCtor('JobOrderBudgetLog', JobOrderBudgetLog);
        metadataStore.registerEntityTypeCtor('JobOrderDetail', JobOrderDetail);
        metadataStore.registerEntityTypeCtor('JobOrderDetailAddon', JobOrderDetailAddon);
        metadataStore.registerEntityTypeCtor('JobOrderHistDetail', JobOrderHistDetail);
        metadataStore.registerEntityTypeCtor('JobOrderHistDetailAddon', JobOrderHistDetailAddon);
        metadataStore.registerEntityTypeCtor('JobOrderHistDetailFeature', JobOrderHistDetailFeature);
        metadataStore.registerEntityTypeCtor('JobOrderHistDetailReceipt', JobOrderHistDetailReceipt);
        metadataStore.registerEntityTypeCtor('JobOrderNote', JobOrderNote);
        metadataStore.registerEntityTypeCtor('JobOrderOveragePayment', JobOrderOveragePayment);
        metadataStore.registerEntityTypeCtor('JobOrderOveragePaymentStatus', JobOrderOveragePaymentStatus);
        metadataStore.registerEntityTypeCtor('JobOrderStatus', JobOrderStatus);
        metadataStore.registerEntityTypeCtor('JobOrderType', JobOrderType);
        metadataStore.registerEntityTypeCtor('LogType', LogType);
        metadataStore.registerEntityTypeCtor('Manifest', Manifest);
        metadataStore.registerEntityTypeCtor('Manufacturer', Manufacturer);
        metadataStore.registerEntityTypeCtor('ManufacturerImage', ManufacturerImage);
        metadataStore.registerEntityTypeCtor('NextProgramAllowanceRule', NextProgramAllowanceRule);
        metadataStore.registerEntityTypeCtor('NotificationEvent', NotificationEvent);
        metadataStore.registerEntityTypeCtor('NotificationEventAccountMap', NotificationEventAccountMap);
        metadataStore.registerEntityTypeCtor('NotificationQuery', NotificationQuery);
        metadataStore.registerEntityTypeCtor('NotificationStatus', NotificationStatus);
        metadataStore.registerEntityTypeCtor('NotificationSubmission', NotificationSubmission);
        metadataStore.registerEntityTypeCtor('NotificationTemplate', NotificationTemplate);
        metadataStore.registerEntityTypeCtor('NotificationType', NotificationType);
        metadataStore.registerEntityTypeCtor('OrderPaidByType', OrderPaidByType);
        metadataStore.registerEntityTypeCtor('PricedAddon', PricedAddon);
        metadataStore.registerEntityTypeCtor('PricedProductType', PricedProductType);
        metadataStore.registerEntityTypeCtor('PricedProductTypeFeatureChoice', PricedProductTypeFeatureChoice);
        metadataStore.registerEntityTypeCtor('Product', Product);
        metadataStore.registerEntityTypeCtor('ProductFeatureChoice', ProductFeatureChoice);
        metadataStore.registerEntityTypeCtor('ProductType', ProductType);
        metadataStore.registerEntityTypeCtor('ProductTypeConfig', ProductTypeConfig);
        metadataStore.registerEntityTypeCtor('ProductTypeConfigAddon', ProductTypeConfigAddon);
        metadataStore.registerEntityTypeCtor('ProductTypeConfigProduct', ProductTypeConfigProduct);
        metadataStore.registerEntityTypeCtor('ProductTypeFeature', ProductTypeFeature);
        metadataStore.registerEntityTypeCtor('ProductTypeImage', ProductTypeImage);
        metadataStore.registerEntityTypeCtor('ProductTypeTag', ProductTypeTag);
        metadataStore.registerEntityTypeCtor('ProductTypeTagMap', ProductTypeTagMap);
        metadataStore.registerEntityTypeCtor('ProductTypeVersion', ProductTypeVersion);
        metadataStore.registerEntityTypeCtor('Program', Program);
        metadataStore.registerEntityTypeCtor('ProgramAccountIssuanceMap', ProgramAccountIssuanceMap);
        metadataStore.registerEntityTypeCtor('ProgramAllowance', ProgramAllowance);
        metadataStore.registerEntityTypeCtor('ProgramAllowanceAddonException', ProgramAllowanceAddonException);
        metadataStore.registerEntityTypeCtor('ProgramAllowanceFeatureException', ProgramAllowanceFeatureException);
        metadataStore.registerEntityTypeCtor('ProgramAllowanceUserGroupMap', ProgramAllowanceUserGroupMap);
        metadataStore.registerEntityTypeCtor('ProgramAllowanceUserLog', ProgramAllowanceUserLog);
        metadataStore.registerEntityTypeCtor('ProgramApplicabilityType', ProgramApplicabilityType);
        metadataStore.registerEntityTypeCtor('ProgramBudget', ProgramBudget);
        metadataStore.registerEntityTypeCtor('ProgramByAccountUserSummary', ProgramByAccountUserSummary);
        metadataStore.registerEntityTypeCtor('ProgramIssuance', ProgramIssuance);
        metadataStore.registerEntityTypeCtor('ProgramIssuanceUserGroupMap', ProgramIssuanceUserGroupMap);
        metadataStore.registerEntityTypeCtor('ProgramIssuanceUserLog', ProgramIssuanceUserLog);
        metadataStore.registerEntityTypeCtor('ProgramProductCategoryTag', ProgramProductCategoryTag);
        metadataStore.registerEntityTypeCtor('ProgramProductCategoryTagMap', ProgramProductCategoryTagMap);
        metadataStore.registerEntityTypeCtor('ProgramProductTypeConfig', ProgramProductTypeConfig);
        metadataStore.registerEntityTypeCtor('ProgramProductTypeTag', ProgramProductTypeTag);
        metadataStore.registerEntityTypeCtor('ProgramProductTypeTagMap', ProgramProductTypeTagMap);
        metadataStore.registerEntityTypeCtor('ProgramRapidTemplate', ProgramRapidTemplate);
        metadataStore.registerEntityTypeCtor('ProgramRapidTemplateProductTypeConfig', ProgramRapidTemplateProductTypeConfig);
        metadataStore.registerEntityTypeCtor('ProgramStartDateType', ProgramStartDateType);
        metadataStore.registerEntityTypeCtor('ProgramSupplier', ProgramSupplier);
        metadataStore.registerEntityTypeCtor('ProgramUserGroup', ProgramUserGroup);
        metadataStore.registerEntityTypeCtor('ProgramUserGroupBudget', ProgramUserGroupBudget);
        metadataStore.registerEntityTypeCtor('ProgramUserGroupExclusion', ProgramUserGroupExclusion);
        metadataStore.registerEntityTypeCtor('ProgramUserGroupMap', ProgramUserGroupMap);
        metadataStore.registerEntityTypeCtor('ProgramView', ProgramView);
        metadataStore.registerEntityTypeCtor('ProgramViewProductTypeConfig', ProgramViewProductTypeConfig);
        metadataStore.registerEntityTypeCtor('ProgramViolationRule', ProgramViolationRule);
        metadataStore.registerEntityTypeCtor('ProximityArea', ProximityArea);
        metadataStore.registerEntityTypeCtor('ProximityPref', ProximityPref);
        metadataStore.registerEntityTypeCtor('ProximityRight', ProximityRight);
        metadataStore.registerEntityTypeCtor('ProximityUser', ProximityUser);
        metadataStore.registerEntityTypeCtor('ProximityUserRight', ProximityUserRight);
        metadataStore.registerEntityTypeCtor('ReturnCreditType', ReturnCreditType);
        metadataStore.registerEntityTypeCtor('ReturnPolicy', ReturnPolicy);
        metadataStore.registerEntityTypeCtor('ReturnReason', ReturnReason);
        metadataStore.registerEntityTypeCtor('ReturnReasonType', ReturnReasonType);
        metadataStore.registerEntityTypeCtor('ReturnRequest', ReturnRequest);
        metadataStore.registerEntityTypeCtor('ReturnRequestDetail', ReturnRequestDetail);
        metadataStore.registerEntityTypeCtor('ReturnRequestDetailStatus', ReturnRequestDetailStatus);
        metadataStore.registerEntityTypeCtor('ReturnRequestNote', ReturnRequestNote);
        metadataStore.registerEntityTypeCtor('ReturnRequestStatus', ReturnRequestStatus);
        metadataStore.registerEntityTypeCtor('ShipFrequency', ShipFrequency);
        metadataStore.registerEntityTypeCtor('Shipment', Shipment);
        metadataStore.registerEntityTypeCtor('ShipmentBox', ShipmentBox);
        metadataStore.registerEntityTypeCtor('ShippingUserGroup', ShippingUserGroup);
        metadataStore.registerEntityTypeCtor('ShippingUserGroupAddressMap', ShippingUserGroupAddressMap);
        metadataStore.registerEntityTypeCtor('ShippingUserGroupMap', ShippingUserGroupMap);
        metadataStore.registerEntityTypeCtor('SmsTemplate', SmsTemplate);
        metadataStore.registerEntityTypeCtor('StockCondition', StockCondition);
        metadataStore.registerEntityTypeCtor('Supplier', Supplier);
        metadataStore.registerEntityTypeCtor('SupplierAccount', SupplierAccount);
        metadataStore.registerEntityTypeCtor('SupplierAdmin', SupplierAdmin);
        metadataStore.registerEntityTypeCtor('SupplierCategory', SupplierCategory);
        metadataStore.registerEntityTypeCtor('SupplierCategoryMap', SupplierCategoryMap);
        metadataStore.registerEntityTypeCtor('SupplierImage', SupplierImage);
        metadataStore.registerEntityTypeCtor('SupplierManifestMap', SupplierManifestMap);
        metadataStore.registerEntityTypeCtor('SupplierSubmission', SupplierSubmission);
        metadataStore.registerEntityTypeCtor('SupplierSubmissionStatus', SupplierSubmissionStatus);
    }
}
