import { UtilFns } from '@utils';
import { Entity, EntityAspect, EntityError } from 'breeze-client';
import * as _ from 'lodash';
import { pick } from 'lodash-es';
import { UnitOfWork } from './unit-of-work';

export class EntityFns {

  static getChangedValues(entity: Entity, fieldName: string) {
    const curValue = entity[fieldName];
    const origValue = entity.entityAspect.originalValues[fieldName];
    return { changed: origValue !== curValue, curValue: curValue, origValue: origValue };
  }

  static deleteOrDetach(entityAspect: EntityAspect) {
    if (entityAspect.entityState.isAdded()) {
      entityAspect.setDetached();
    } else if (entityAspect.entityState.isUnchangedOrModified()) {
      entityAspect.setDeleted();
    }
    return entityAspect.entityState;
  }

  static hasValueChanged(entity: Entity, fieldName: string) {
    const origValue = entity.entityAspect.originalValues[fieldName];
    return origValue !== entity[fieldName];
  }

  static copyEntity<T extends Entity>(uow: UnitOfWork, entityType: { new (): T }, entity: T, propsToExclude: string[], setNewValuesFn: (newStruct: T) => void) {
    const structCopy = EntityFns.cloneStruct(entity, propsToExclude) as T;
    setNewValuesFn(structCopy);
    const newEntity = uow.createEntity(entityType, structCopy);
    return newEntity;
  }

  

  static cloneStruct(entity: Entity, propsToExclude: string[], extraProps: string[] = []) {
    var dps = entity.entityType.dataProperties
      .map(dp => dp.name)
      .filter(nm => !propsToExclude.includes(nm));
    dps = dps.concat(...extraProps)
    const clone = pick(entity, dps) as Object;
    return clone;
  }

  /** Get the values of the entity's foreign key properties */
  static getForeignKeyValues(entity: Entity) {
    const keys: any[] = [];
    for (const prop of entity.entityType.navigationProperties) {
      for (const n of prop.foreignKeyNames) {
        const v = entity[n];
        if (v) {
          keys.push(v);
        }
      }
    }
    return keys;
  }

  static undoIfDeleted<T>(uow: UnitOfWork, type: new () => T, keyValues: any[] ) {
    const ent = uow.getEntityByKey(type, keyValues);
    if (ent) {
      const es = ent?.entityAspect.entityState;
      if (es?.isDeleted()) {
        ent.entityAspect.rejectChanges();
        return true;
      }
    }
    return false;
  }

  private static logEntity(e: Entity, alreadyLogged: Entity[], depth: number, maxDepth: number) {
    if (depth >= maxDepth || !e) {
      return;
    }
    let indent = '    '.repeat(depth);
    console.log(indent + e.entityType.shortName + ': ' + e.entityAspect.getKey().values + ' (' + e.entityAspect.entityState.name + ')');

    if (alreadyLogged.includes(e)) {
      return;
    }
    alreadyLogged.push(e);

    indent += '  ';
    // log data properties
    const dataProps = e.entityType.dataProperties;
    dataProps.forEach(p => {
      console.log(indent + p.name + ': ' + e[p.name]);
    });

    // log navigation properties
    const navProps = e.entityType.navigationProperties;
    navProps.forEach(p => {
      const val = e[p.name];
      if (Array.isArray(val)) {
        console.log(indent + p.name + ': [');
        val.forEach(v => {
          this.logEntity(v, alreadyLogged, depth + 1, maxDepth);
        });
        console.log(indent + ']');
      } else {
        console.log(indent + p.name + ': ');
        this.logEntity(val, alreadyLogged, depth + 1, maxDepth);
      }
    });
  }

  static logEntities(entities: Entity[], maxDepth: number) {
    const alreadyLogged = [];
    entities.forEach(e => {
      this.logEntity(e, alreadyLogged, 0, maxDepth);
    });
  }

  /** Make the validation errors into a <br> separated string */
  static formatValidationErrors(errEntities: Entity[]) {
    const valErrors = _.flatMap(errEntities, e => e.entityAspect.getValidationErrors());
    const errMessage = valErrors.map(e => e.propertyName + ': ' + e.errorMessage).join('<br/>');
    return errMessage;
  }


  /** Format the error, which may contain EntityErrors */
  static formatErrorMessage(err: Error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ees: EntityError[] = (err as any).entityErrors;
    if (ees) {
      const msgs = ees.map((e) => (e.propertyName || e.entity?.entityType?.shortName || e.errorName) + ': ' + e.errorMessage);
      return msgs.join('; ');
    } else {
      return err.message;
    }
  }

  /** Make EntityErrors from the validation errors on the entities */
  static makeEntityErrors(errEntities: Entity[]): EntityError[] {
    const entityErrors: EntityError[] = [];
    for (const ent of errEntities) {
      const verrs = ent.entityAspect.getValidationErrors();
      for (const verr of verrs) {
        entityErrors.push({
          entity: ent,
          errorMessage: verr.errorMessage,
          propertyName: verr.propertyName,
          errorName: verr.validator?.name || verr.key,
          isServerError: false
        })
      }
    }
    return entityErrors;
  }

  /** Return an error containing EntityErrors */
  static makeError(message: string, errEntities: Entity[]): Error {
    const entityErrors = this.makeEntityErrors(errEntities);
    const error = new Error(message);
    if (entityErrors.length) {
      error['entityErrors'] = entityErrors;
    }
    return error;
  }

  static checkForDupErrors<T>(entities: T[] | undefined, extractValueFn: (item: T) => string, handleError: (e: Entity, dupName: string) => void ) {

    if (entities == null || entities.length == 0) return;
    const map = UtilFns.getDuplicatesOnProp(entities, extractValueFn) as  object;
    Object.keys(map).forEach(dupName => {
      const entities = map[dupName];
      entities.forEach(e => {
        handleError(e, dupName);
      });
    });
  }

}
