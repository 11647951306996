<prox-main  [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>

<ng-template #bodyTemplate>
  <div class="mb-2">
    <button (click)="onAdd()" [disabled]="!canAdd()" pButton type="button" label="Add Template" icon="pi pi-plus"
      class="p-button-sm"></button>
    <button (click)="onCopy()" [disabled]="!canCopy()" pButton type="button" label="Copy Template" icon="pi pi-copy"
      class="p-button-sm ml-2"></button>
    <p-fileUpload *ngIf="isSuperUser" mode="basic" name="demo[]" chooseIcon="pi pi-upload" accept="application/json" [maxFileSize]="1000000"
      (uploadHandler)="uploadHander($event)" [auto]="true" [customUpload]="true" chooseLabel="Upload Templates"
      class="inline-flex" styleClass="p-button-sm ml-4" />
    <button *ngIf="isSuperUser" (click)="downloadAll()" pButton type="button" label="Download Templates" icon="pi pi-download"
      class="p-button-sm ml-2"></button>
  </div>

  <div class="flex-grow-1" *ngIf="isPageReady">
    <div class="pt-2 pb-2 w-full h-full">
      <prox-ag-grid [gridOptions]="gridOptions" [rowData]="templates"></prox-ag-grid>
    </div>
  </div>
  <p-confirmDialog></p-confirmDialog>
</ng-template>
