import { Route } from '@angular/router';
import { accountCanActivateGuard } from './services/account-can-activate.guard';
import { canDeactivateGuard } from '@core';
import { AccountListComponent } from './account-list.component';
import { AccountManagerComponent } from './account-manager.component';
import { AccountUserFrmComponent } from './account-users/account-user-frm.component';
import { AccountUserListComponent } from './account-users/account-user-list.component';
import { AccountAdminListComponent } from './account-administrator/account-administrator-list.component';
import { AccountAdminFrmComponent } from './account-administrator/account-administrator-frm.component';
import { AccountFrmComponent } from './account-frm.component';
import { ProgramUserGroupsComponent } from './account-users/program-user-groups.component';
import { ProgramListComponent } from './programs/program-list.component';
import { ProgramFrmComponent } from './programs/program-frm.component';
import { ProximityRightEnum } from '@models';
import { ShippingAccountAddressesComponent } from './addresses/shipping-account-addresses.component';
import { AccountAddressFrmComponent  } from './addresses/account-address-frm.component';
import { PriceListsComponent } from './price-lists/price-lists.component';
import { ApprovalTreesComponent } from './approval-trees/approval-trees.component';
import { AccountAdminGroupsComponent } from './account-administrator/account-admin-groups.component';
import { BillingAccountAddressesComponent } from './addresses/billing-account-addresses.component';
import { AccountBlanketPurchaseOrdersComponent } from './purchase-orders/account-blanket-purchase-orders.component';
import { ShippingUserGroupsComponent } from './account-users/shipping-user-groups.component';
import { BudgetProductTypeTagsComponent } from './product-type-tags/budget-product-type-tags.component';
import { AccountBudgetsComponent } from './budgets/account-budgets.component';
import { ActionListComponent } from './actions/action-list.component';
import { ActionFrmComponent } from './actions/action-frm.component';
import { ProgramChangeStatusFrmComponent } from './programs/program-change-status-frm.component';
import { ProgramProductTypeTagsComponent } from './product-type-tags/program-product-type-tags.component.';
import { ImportUsersComponent } from './import-users/import-users.component';
import { AccountNotificationTemplateListComponent } from './notifiications/account-notiification-template-list.component';
import { ProgramNarrativeComponent } from './programs/program-narrative.component';
import { ApiSendStatusComponent } from './api-send-status.component';
import { AccountNotificationSettingsComponent } from './notifiications/account-notification-settings.component';

export const accountRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'accounts',
    pathMatch: 'full',
  },
  {
    path: 'accounts',
    canActivate: [accountCanActivateGuard],
    component: AccountListComponent,
  },
  {
    path: 'admin',
    canActivate: [accountCanActivateGuard],
    title: 'Proximity - Admin',
    children: [
      {
        path: 'apisendstatus',
        canActivate: [accountCanActivateGuard],
        component: ApiSendStatusComponent,
        title: 'API - Send Status'
      },
    ]
  },
  {
    path: 'account-add-edit/:accountId',
    canActivate: [accountCanActivateGuard],
    canDeactivate: [canDeactivateGuard],
    component: AccountFrmComponent,
  },
  {
    path: 'accounts/:accountId',
    canActivate: [accountCanActivateGuard],
    children: [
      {
        path: '',
        redirectTo: 'manage',
        pathMatch: 'full'
      },
      {
        path: 'manage',
        canActivate: [accountCanActivateGuard],
        component: AccountManagerComponent,
        children: [
          {
            path: '',
            redirectTo: 'account-info',
            pathMatch: 'full',
          },
          {
            path: 'account-info',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountFrmComponent,
          },
          {
            path: 'account-administrators',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountAdminListComponent,
          },
          {
            path: 'account-administrators/:accountAdminId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountAdminFrmComponent,
          },
          {
            path: 'account-admin-groups',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // data: { rights: [ProximityRightEnum.AccountAdminUpdate] },
            component: AccountAdminGroupsComponent,
          },
          {
            path: 'account-actions',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: ActionListComponent,
          },
/*           {
            path: 'message-template',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: ActionListComponent,
          }, */
          {
            path: 'account-actions/:accountActionId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: ActionFrmComponent,
          },
          {
            path: 'account-blanket-purchase-orders',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // data: { rights: [ProximityRightEnum.CanCreateAccountUser, ProximityRightEnum.CanEditAnyAccountUser] },
            component: AccountBlanketPurchaseOrdersComponent,
          },
          {
            path: 'account-users',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanCreateAccountUser, ProximityRightEnum.CanEditAnyAccountUser] },
            component: AccountUserListComponent,
          },
          {
            path: 'account-notification-templates',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountNotificationTemplateListComponent,
          },
          {
            path: 'account-notification-settings',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountNotificationSettingsComponent,
          },
          {
            path: 'account-users/import/:accountId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ImportUsersComponent,
          },
          {
            path: 'shipping-user-groups',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ShippingUserGroupsComponent,
          },
/*           {
            path: 'shipping-user-groups/:groupId/:name',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ImportUsersComponent,
          }, */
          {
            path: 'program-user-groups',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ProgramUserGroupsComponent,
          },
          {
            path: 'approval-trees',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ApprovalTreesComponent,
          },
          {
            path: 'shipping-account-addresses',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ShippingAccountAddressesComponent,
          },
          {
            path: 'billing-account-addresses',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: BillingAccountAddressesComponent,
          },
          {
            path: 'shipping-account-addresses/:accountAddressId/:addressType',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: AccountAddressFrmComponent,
          },
          {
            path: 'billing-account-addresses/:accountAddressId/:addressType',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: AccountAddressFrmComponent,
          },
          {
            path: 'budget-product-type-tags',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: BudgetProductTypeTagsComponent,
          },
          {
            path: 'program-product-type-tags',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: ProgramProductTypeTagsComponent,
          },
          {
            path: 'account-budgets',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: AccountBudgetsComponent,
          },
          {
            path: 'account-price-lists',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // TODO: Create a right for this
            // data: { rights: [ProximityRightEnum.CanEditAnyAccountUser] },
            component: PriceListsComponent,
          },
          {
            path: 'programs',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            // don't use rights here because any admin is allowed to see programs that he is an account admin for
            component: ProgramListComponent,
          },
          {
            path: 'programs/:programId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: ProgramFrmComponent,
          },
          {
            path: 'programs/status/:programId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: ProgramChangeStatusFrmComponent,
          },
          {
            path: 'programs/narrative/:programId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: ProgramNarrativeComponent,
          },
          {
            path: 'account-users/:accountUserId',
            canActivate: [accountCanActivateGuard],
            canDeactivate: [canDeactivateGuard],
            component: AccountUserFrmComponent,
          }
        ],
      },
    ],
  },
  
];
