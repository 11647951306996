import {
  CellValueChangedEvent,
  ColDef,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  RowDataUpdatedEvent,
} from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, BaseService, ISortModel, SaveAndQueryComponent } from '@core';
import { UtilFns } from '@utils';
import { MenuItem } from 'primeng/api';
import * as XLSX from 'xlsx';
import { Guid } from 'guid-typescript';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs';
import * as _ from 'lodash';
import {
  Account,
  AccountImportCodeOptionEnum,
  GenderEnum,
  ImportUser,
  ProgramUserGroup,
  ShippingUserGroup,
} from '@models';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { ConfirmationService } from 'primeng/api';

// https://github.com/exceljs/exceljs?tab=readme-ov-file#writing-xlsx

export class ShippingCode {
  code = '';
  shippingUserGroupId = '';
}

export class ProgramCode {
  code = '';
  programUserGroupId = '';
}

@Component({
  selector: 'prox-import-users',
  templateUrl: './import-users.component.html',
})
export class ImportUsersComponent extends SaveAndQueryComponent {
  accountId?: string;
  account?: Account;
  groupId?: string;
  //name?: string;
  importUsers: ImportUser[] = [];
  items: MenuItem[] = [];
  hasErrors = false;
  importRunning = false;
  includeCodes = true;

  gridOptions!: GridOptions;
  shippingCodesGridOptions!: GridOptions;
  programCodesGridOptions!: GridOptions;

  shippingUserGroups: ShippingUserGroup[] = [];
  programUserGroups: ProgramUserGroup[] = [];

  shippingCodes: ShippingCode[] = [];
  programCodes: ProgramCode[] = [];

  // shippingGroupMap!: object;
  // programGroupMap!: object;
  e?: RowDataUpdatedEvent<ShippingCode, any> = undefined;

  rowClassRules = {
    'prx-error-row': function (params) {
      return params.data.Status === 'ERROR';
    },
  };

  acceptedFiles = '.xls, .xlsx, .csv';
  blobType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'AccountId');

    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');

    /*     this.groupId = params['groupId'];
    UtilFns.assertNonEmptyString(this.groupId);

    this.name = params['name'];
    UtilFns.assertNonEmptyString(this.name); */

    if (this.includeCodes) {
      this.shippingUserGroups = await this.dbQueryService.getShippingUserGroups(
        this.accountId
      );
      this.programUserGroups = await this.dbQueryService.getProgramUserGroups(
        this.accountId
      );
      // this.shippingGroupMap = UtilFns.objectArrayToObject(
      //   this.shippingUserGroups,
      //   'id',
      //   'name'
      // );
      // this.programGroupMap = UtilFns.objectArrayToObject(
      //   this.programUserGroups,
      //   'id',
      //   'name'
      // );
    }

    this.buildMainMenu();

    this.gridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelectedDefault,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const importUser = rowIdParams.data as ImportUser;
        return importUser.Id;
      },
      rowModelType: 'clientSide',
    });

    this.shippingCodesGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.shippingCodesOnGridReady,
      onCellValueChanged: this.onShippingGroupCellValueChanged,
      onRowSelected: this.onRowSelectedDefault,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const shippingCode = rowIdParams.data as ShippingCode;
        return shippingCode.code;
      },
      rowModelType: 'clientSide',
    });

    this.programCodesGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.programCodesOnGridReady,
      onCellValueChanged: this.onProgramGroupCellValueChanged,
      onRowSelected: this.onRowSelectedDefault,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const programCode = rowIdParams.data as ProgramCode;
        return programCode.code;
      },
      rowModelType: 'clientSide',
    });
    this.setTitle("Account User Import for " + this.account.name);
    this.isPageReady = true;
  }

  onGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.applyGridRouteParams(this.gridOptions);
  }

  getShippingGroupName(id: string) {
    return this.shippingUserGroups.find((p) => p.id == id)?.name;
  }

  onShippingGroupCellValueChanged(event: CellValueChangedEvent) {
    const shippingGroupName = this.getShippingGroupName(
      event.data.shippingUserGroupId
    );
    this.updateImportUserShippingGroup(
      event.data.code,
      event.data.shippingUserGroupId,
      shippingGroupName
    );
  }

  updateImportUserShippingGroup(
    code: string,
    shippingUserGroupId: string,
    shippingUserGroupName?: string
  ) {
    console.log(
      'shipping code: ' +
        code +
        ' shippingUserGroupId: ' +
        shippingUserGroupId +
        ' name: ' +
        shippingUserGroupName
    );
    this.importUsers.forEach((p) => {
      if (p.ShippingUserGroupCode == code) {
        p.ShippingUserGroupId = shippingUserGroupId;
        p.ShippingUserGroupName = shippingUserGroupName;
      }
    });
    this.gridOptions.api?.refreshCells();
  }

  getProgramGroupName(id: string) {
    return this.programUserGroups.find((p) => p.id == id)?.name;
  }

  onProgramGroupCellValueChanged(event: CellValueChangedEvent) {
    const programGroupName = this.getProgramGroupName(
      event.data.programUserGroupId
    );
    this.updateImportUserProgramGroup(
      event.data.code,
      event.data.programUserGroupId,
      programGroupName
    );
  }

  updateImportUserProgramGroup(
    code: string,
    programUserGroupId: string,
    programUserGroupName?: string
  ) {
    this.importUsers.forEach((p) => {
      console.log(
        'program code: ' +
          code +
          ' programUserGroupId: ' +
          programUserGroupId +
          ' name: ' +
          programUserGroupName
      );
      if (p.ProgramUserGroupCode == code) {
        p.ProgramUserGroupId = programUserGroupId;
        p.ProgramUserGroupName = programUserGroupName;
      }
    });
    this.gridOptions.api?.refreshCells();
  }

  getColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      {
        headerName: 'Status',
        field: 'Status',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Error Message',
        colId: 'Error',
        field: 'Error',
        width: 220,
        maxWidth: 220,
        hide: true,
      },
      {
        headerName: 'Last Name',
        field: 'LastName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'First Name',
        field: 'FirstName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Middle Name',
        field: 'MiddleName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Salutation',
        field: 'Salutation',
        filter: 'agTextColumnFilter',
      },
      { headerName: 'Gender', field: 'Gender', filter: 'agTextColumnFilter' },
      { headerName: 'Email', field: 'Email', filter: 'agTextColumnFilter' },
      {
        headerName: 'ApiLink',
        field: 'ApiLink',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'New Hire End-Date',
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleDateString() : '';
        },
        field: 'NewHireEndDate',
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'Shipping Group Code',
        field: 'ShippingUserGroupCode',
        filter: 'agTextColumnFilter',
        hide: !this.includeCodes,
      },
      {
        headerName: 'Shipping Group Name',
        field: 'ShippingUserGroupName',
        filter: 'agTextColumnFilter',
        hide: !this.includeCodes,
      },
      {
        headerName: 'Program Group Code',
        field: 'ProgramUserGroupCode',
        filter: 'agTextColumnFilter',
        hide: !this.includeCodes,
      },
      {
        headerName: 'Program Group Name',
        field: 'ProgramUserGroupName',
        filter: 'agTextColumnFilter',
        hide: !this.includeCodes,
      },
    ];
    const sortModel: ISortModel = [{ colId: 'LastName', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  shippingCodesOnGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getShippingCodesColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
  }

  getShippingCodesColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      {
        headerName: 'Code',
        field: 'code',
        width: 100,
        sortable: false,
        editable: false,
      },
      {
        headerName: 'Shipping User Group',
        editable: true,
        ...AgFns.createDropdownEditor('shippingUserGroupId', this.shippingUserGroups),
      },
      
    ];
    const sortModel: ISortModel = [{ colId: 'code', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  programCodesOnGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getProgramCodesColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
  }

  getProgramCodesColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      {
        headerName: 'Code',
        field: 'code',
        width: 100,
        sortable: false,
        editable: false,
      },
      {
        headerName: 'Program User Group',
        editable: true,
        ...AgFns.createDropdownEditor('programUserGroupId', this.programUserGroups),
      },
    ];
    const sortModel: ISortModel = [{ colId: 'code', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  updateColumnVisibility() {
    const colApi = this.gridOptions.columnApi;
    if (colApi) {
      colApi.setColumnsVisible(['Error'], this.hasErrors);
    }
  }

  rowEdit() {
    this.toastr.info('Redraw...');
    this.gridOptions.api?.redrawRows();
  }

  private buildMainMenu() {
    this.items = [
      {
        label: 'Download Template',
        icon: 'pi pi-fw pi-cog',
        command: () => {
          this.getTemplate();
        },
      },
    ];
  }

  async getTemplate() {
    await this.exportAsExcelFile('UsersTemplate.xlsx');
  }

  // https://www.codeproject.com/Tips/1251189/Excel-Export-from-Angular-2plus

  async exportAsExcelFile(excelFileName: string): Promise<void> {
    const workbook = new Excel.Workbook();
    workbook.addWorksheet('AccountUsers', { views: [{ activeCell: 'A1' }] });
    const sheet: Excel.Worksheet = <Excel.Worksheet>workbook.getWorksheet(1);
    if (sheet) {
      if (this.includeCodes) {
        sheet.columns = [
          { header: 'LastName', key: 'LastName', width: 30 },
          { header: 'FirstName', key: 'FirstName', width: 30 },
          { header: 'MiddleName', key: 'MiddleName', width: 30 },
          { header: 'Salutation', key: 'Salutation', width: 10 },
          { header: 'Gender', key: 'Gender', width: 10 },
          { header: 'Email', key: 'Email', width: 30 },
          { header: 'ApiLink', key: 'ApiLink', width: 30 },
          { header: 'NewHireEndDate', key: 'NewHireEndDate', width: 30 },
          {
            header: 'ShippingUserGroupCode',
            key: 'shippingUserGroupCode',
            width: 30,
          },
          {
            header: 'ProgramUserGroupCode',
            key: 'programUserGroupCode',
            width: 30,
          },
        ];
      } else {
        sheet.columns = [
          { header: 'LastName', key: 'LastName', width: 30 },
          { header: 'FirstName', key: 'FirstName', width: 30 },
          { header: 'MiddleName', key: 'MiddleName', width: 30 },
          { header: 'Salutation', key: 'Salutation', width: 10 },
          { header: 'Gender', key: 'Gender', width: 10 },
          { header: 'Email', key: 'Email', width: 30 },
          { header: 'ApiLink', key: 'ApiLink', width: 30 },
          { header: 'NewHireEndDate', key: 'NewHireEndDate', width: 30 },
        ];
      }
    }

    sheet.getCell('A1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    sheet.getCell('B1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('B1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    sheet.getCell('C1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('C1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    sheet.getCell('D1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('D1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    sheet.getCell('E1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('E1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    sheet.getCell('F1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('F1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    sheet.getCell('G1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('G1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    sheet.getCell('H1').font = {
      color: { argb: 'FFFFFF' },
    };

    sheet.getCell('H1').fill = {
      type: 'pattern',
      pattern: 'solid',
      bgColor: { argb: '000000' },
    };

    if (this.includeCodes) {
      sheet.getCell('I1').font = {
        color: { argb: 'FFFFFF' },
      };

      sheet.getCell('I1').fill = {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: '000000' },
      };

      sheet.getCell('J1').font = {
        color: { argb: 'FFFFFF' },
      };

      sheet.getCell('J1').fill = {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: '000000' },
      };
    }

    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, excelFileName);
    });
  }

  //https://stackoverflow.com/questions/76488660/how-can-i-upload-and-parse-my-local-excel-inside-an-angular-component-when-it-l

  async onLoad(e: any) {
    const reader = new FileReader();
    let result = {};
    let roa = [];
    let tempImportUsers: ImportUser[] = [];
    reader.readAsBinaryString(e.files[0]);
    reader.onloadend = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, {
        type: 'binary',
      });

      result = {};
      workbook.SheetNames.forEach((c) => {
        roa = XLSX.utils.sheet_to_json(workbook.Sheets[c]);
        if (roa.length > 0) {
          result[c] = roa;
        }
      });

      this.importUsers = [];
      tempImportUsers = [];

      roa.forEach((c: ImportUser) => {
        const rec = new ImportUser();
        rec.Id = Guid.create().toString();
        rec.LastName = c.LastName;
        rec.FirstName = c.FirstName;
        rec.MiddleName = c.MiddleName;
        rec.Status = 'Ready';
        rec.Error = '';
        rec.Email = c.Email;
        rec.Salutation = c.Salutation;
        rec.Gender = this.setGender(c.Gender);
        rec.ApiLink = c.ApiLink;
        rec.NewHireEndDate = c.NewHireEndDate;
        rec.ProgramUserGroupCode = c.ProgramUserGroupCode;
        rec.ShippingUserGroupCode = c.ShippingUserGroupCode;
        rec.Result = '';
        tempImportUsers.push(rec);
      });

      this.importUsers = tempImportUsers;
      if (this.includeCodes) {
        this.loadCodes();
      }
      this.onTest();
    };
  }

  loadCodes() {
    this.shippingCodes = [];
    this.programCodes = [];

    const uniqueShippingCodes = [
      ...new Set(this.importUsers.map((item) => item.ShippingUserGroupCode)),
    ];
    const uniqueProgramCodes = [
      ...new Set(this.importUsers.map((item) => item.ProgramUserGroupCode)),
    ];

    uniqueShippingCodes.forEach((p) => {
      const c = new ShippingCode();
      c.code = <string>p;
      if (c.code) {
        this.shippingCodes.push(c);
      }
    });

    uniqueProgramCodes.forEach((p) => {
      const d = new ProgramCode();
      d.code = <string>p;
      if (d.code) {
        this.programCodes.push(d);
      }
    });
  }

  submitDisabled() {
    return this.importUsers.length == 0;
  }

  setGender(gender: string | null) {
    if (!gender) return 'NotSpecified';
    if (
      gender.toUpperCase() == 'M' ||
      gender.toUpperCase() == 'MALE' ||
      gender.toUpperCase() == 'F' ||
      gender.toUpperCase() == 'FEMALE'
    )
      return gender;
    return '';
  }

  onTest() {
    this.hasErrors = false;
    if (this.importUsers.length == 0) {
      this.toastr.warning('No records');
    }
    const dupAL = UtilFns.getDuplicates(
      this.importUsers.filter((f) => f.ApiLink).map((x) => x.ApiLink)
    );

    if (dupAL.length > 0) {
      dupAL.forEach((d) => {
        this.importUsers.forEach((c) => {
          if (c.ApiLink == d) {
            this.hasErrors = true;
            c.Status = 'ERROR';
            c.Error = 'Duplicate API Link';
          }
        });
      });

      const dupEm = UtilFns.getDuplicates(
        this.importUsers.filter((f) => f.Email).map((x) => x.Email)
      );
      dupEm.forEach((d) => {
        this.importUsers.forEach((c) => {
          if (c.Email == d) {
            this.hasErrors = true;
            c.Status = 'ERROR';
            c.Error = 'Duplicate E-mail Address';
          }
        });
      });

      this.importUsers.forEach((c) => {
        if (!c.LastName || !c.FirstName || !c.Email) {
          c.Status = 'ERROR';
          c.Error = 'Required field(s) missing';
        }
      });

      this.gridOptions.api?.refreshCells();
      this.updateColumnVisibility();
    }

    if (this.hasErrors) {
      this.toastr.warning(
        'There are errors in the import list.  You can either fix the errors and re-import or submit as is.  Records with errors will not be posted to the database.',
        'Errors in Import'
      );
    }
  }

  async onSubmit() {
    if (
      this.account?.accountImportCodeOptionId !==
      AccountImportCodeOptionEnum.None
    ) {
      const hasNullSCodes = this.hasNullShippingCode();
      console.log('nullscodes: ' + hasNullSCodes);
      if (hasNullSCodes) {
        if (
          this.account?.accountImportCodeOptionId ==
          AccountImportCodeOptionEnum.Shipping
        ) {
          this.dialogService.okDialog(
            'Information Required',
            'All Shipping Import Codes must first be applied'
          );
          return;
        }
      }

      const hasNullPCodes = this.hasNullProgramCode();
      if (hasNullPCodes) {
        if (
          this.account?.accountImportCodeOptionId ==
          AccountImportCodeOptionEnum.Program
        ) {
          this.dialogService.okDialog(
            'Unable To Submit',
            'All Program Import Codes must first be applied'
          );
          return;
        }

        if ((hasNullSCodes  && (this.account?.accountImportCodeOptionId ==
          AccountImportCodeOptionEnum.Both))) {
          this.dialogService.okDialog(
            'Unable To Submit',
            'All Shipment and Program Import Codes must first be applied'
          );
          return;
        }
      }
    }

    this.confirmationService.confirm({
      message:
        'There are errors in the import list.  You can either fix the errors and re-import or submit as is.  Records with errors will not be posted to the database.  Proceed with submit?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: async () => {
        this.importRunning = true;
        await this.dbSaveService
          .postImportAccountUsers(<string>this.accountId, this.importUsers)
          .then((_) => {
            //this.toastr.success('Import list has been sent to queue.  These Account Users will appear in your Account Users list soon.', "Submitted to Queue");
            this.importRunning = false;
            this.toastr.success(
              'Account Users have been imported.',
              'Import Complete'
            );
            this.navigateBack();
          });
      },
      reject: () => {
        //
      },
    });
  }

  private hasNullShippingCode() {
    var result = false;
    this.shippingCodes.forEach((c) => {
      console.log('c.code: ' + JSON.stringify(c));
      if (!c.shippingUserGroupId) {
        result = true;
      }
    });
    return result;
  }

  private hasNullProgramCode(): boolean | undefined {
    var result = false;
    this.programCodes.forEach((c) => {
      console.log('c.code: ' + JSON.stringify(c));
      if (!c.programUserGroupId) {
        result = true;
      }
    });
    return result;
  }

  private getGenderId(gender: string): GenderEnum | undefined {
    if (gender.toUpperCase() === 'M' || gender.toUpperCase() == 'MALE') {
      return GenderEnum.Male;
    }
    if (gender.toUpperCase() === 'F' || gender.toUpperCase() == 'FEMALE') {
      return GenderEnum.Female;
    }
    return GenderEnum.NotSpecified;
  }
}
