<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>Product Tags</h5>
  </div>
  <p-splitter  class="flex-grow-1 w-full" layout="vertical" styleClass="h-full"
    [panelSizes]="[60, 40]">
    <ng-template pTemplate>
      <p-splitter [panelSizes]="[50, 50]" class="w-full pb-7" styleClass="h-full">
        <ng-template pTemplate>
          <div class="flex-column w-full h-full">
            <div class="flex pb-2">
              <div class="pt-2 mb-2 pr-3">
                <label>Tags</label>
              </div>
            </div>
            <prox-ag-grid [gridOptions]="ppttGridOptions" [rowData]="pptts"></prox-ag-grid>
          </div>
        </ng-template>
        <ng-template pTemplate>
          <div class="flex-column pr-1 w-full h-full">
            <div class="flex pb-2">
              <div class="pt-2 mb-2 pr-3">
                <label>Categories</label>
              </div>
              <button [disabled]="parent.isReadOnly()" (click)="onPpctAdd()" pButton type="button" label="Add Category" icon="pi pi-plus"
                class="p-button-sm"  ></button>
            </div>
            <prox-ag-grid [gridOptions]="ppctGridOptions" [rowData]="ppcts"></prox-ag-grid>
          </div>
        </ng-template>
      </p-splitter>
    </ng-template>
    <ng-template pTemplate>
      <div class="flex flex-grow-1 w-full">

        <div class="flex flex-column h-full w-full">
          <div class="flex mt-2">
            <h6>Product Types in this Program with Tag: {{this.selectedPptt?.name}} </h6>
          </div>
          <div class="flex-column w-full h-full pb-2 ">
            <prox-ag-grid [gridOptions]="tmapGridOptions" [rowData]="tmaps">
            </prox-ag-grid>
          </div>
        </div>
      </div>
    </ng-template>
  </p-splitter>
</div>
