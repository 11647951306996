import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AuthService, ImpersonationService, Roles } from '@core';
import { MenuItem } from 'primeng/api';

// CUSTOM 3/31/2024

@Component({
    selector: 'prox-app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    constructor(private impersonationService: ImpersonationService, private authService: AuthService) {}

    model: MenuItem[] = [];
    selectedIndex = 2;

    clicked(index: number) {
        this.clearAll();
        this.model[index].icon = this.model[index].icon + ' fa-fade';
        this.selectedIndex = index;
    }

    clearAll() {
        this.model.forEach(i => {
            i.icon = i.icon?.replace('fa-fade', '');
        });
    }

    ngOnInit() {
        const user = this.authService.getUser();
        this.model = [
            {
                label: 'Home',
                icon: 'pi pi-home',
                routerLink: ['/']
            },
            {
                label: 'Supplier Management',
                icon: 'fa-regular fa-boxes-packing',
                routerLink: ['/supplier/suppliers'],
                visible: user?.hasRole(Roles.SuperUser, Roles.SupplierAdmin)
            },
            {
                label: 'Account Management',
                icon: 'fa-regular fa-handshake',
                routerLink: ['/account/accounts'],
                visible: user?.hasRole(Roles.SuperUser, Roles.AccountAdmin)
            },
            {
                label: 'Account Management',
                icon: 'pi pi-fw pi-building',
                routerLink: ['/account/accounts', user?.accountId, 'manage', 'account-users', user?.accountUserId],
                visible: user?.hasRole(Roles.AccountUser)
            },
            {
                label: 'Transactions',
                icon: 'fa-regular fa-message-dollar',
                routerLink: ['/transaction'],
                visible: user?.hasRole(Roles.SuperUser, Roles.AccountAdmin, Roles.AccountUser)
            },
            {
                label: 'Notifications',
                icon: 'pi pi-fw pi-building',
                routerLink: ['/transaction/inapp-notifications', user?.accountId],
                visible: user?.hasRole(Roles.AccountUser, Roles.AccountAdmin)
            },
            { separator: true },
            {
                label: 'Admin View',
                icon: 'pi pi-eye',
                visible: user?.hasRole(Roles.SuperUser),
                items: [
                    {
                        label: 'Change Log',
                        icon: 'pi pi-list',
                        routerLink: ['/supplier/admin/changelog'],
                    },
                    {
                        label: 'Users',
                        icon: 'pi pi-user',
                        routerLink: ['/supplier/admin/userlist'],
                    },
                    {
                        label: 'Orders',
                        icon: 'pi pi-list',
                        routerLink: ['/transaction/orders'],
                    },
                    {
                        label: 'Impersonate',
                        icon: 'pi pi-user',
                        command: () => this.impersonationService.openDialog(),
                    },
                    {
                        label: 'API Status',
                        icon: 'pi pi-user',
                        routerLink: ['/account/admin/apisendstatus'],
                    },
                ]
            },
        ]
    }
}
