/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColDef,
  GetRowIdParams,
  Grid,
  GridOptions,
  GridReadyEvent,
  RowSelectedEvent,
} from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AgFns,
  AgInfoTooltip,
  ISortModel,
  ProductFns,
  ProxAgFns,
  QueryComponent,
} from '@core';
import {
  FeatureChoice,
  PricedProductType,
  PricedProductTypeFeatureChoice,
  ProductTypeConfigProduct,
  ProductType,
  ProductTypeConfig,
  ProductTypeConfigAddon,
  Supplier,
  StockCondition,
  Account,
} from '@models';
import { BaseService } from '@core';
import { UtilFns } from '@utils';
import * as _ from 'lodash';

import { AccountDbQueryService } from '../services/account-db-query.service';

@Component({
  selector: 'prox-price-lists',
  templateUrl: './price-lists.component.html',
})
export class PriceListsComponent extends QueryComponent {
  accountId!: string;
  account?: Account;
  pptGridOptions!: GridOptions;
  selectedPpt?: PricedProductType;
  pptMap!: Map<string, PricedProductType>; // key is the ProductTypeId
  pptfcMap!: Map<FeatureChoice, PricedProductTypeFeatureChoice>;
  ptcs: ProductTypeConfig[] = [];
  selectedPtc?: ProductTypeConfig;
  ptcpGridOptions!: GridOptions;
  ptcps: ProductTypeConfigProduct[] = [];
  ptcaGridOptions!: GridOptions;
  ptcas: ProductTypeConfigAddon[] = [];
  supplierSet = new Set<Supplier>();

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService
  ) {
    super(baseService, route, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');
    this.account = await this.dbQueryService.getAccountById(this.accountId);
    await this.dbQueryService.getAll(StockCondition);
    this.pptGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPptGridReady,
      onRowSelected: this.onPptRowSelected,
      rowModelType: 'serverSide',
    });
    AgFns.captureGridRouteParams(this.pptGridOptions, this.route, 'id');

    // ------------------------------------------

    this.ptcpGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPtcpGridReady,
        masterDetail: true,
        getRowId: (rowIdParams: GetRowIdParams) => {
          const pc = rowIdParams.data as ProductTypeConfigProduct;
          return pc.productId;
        },
      },
      // { detailProperty: 'productTypeConfig.productTypeConfigAddons' }
    );

    this.ptcaGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPtcaGridReady,
        masterDetail: true,
        getRowId: (rowIdParams: GetRowIdParams) => {
          const x = rowIdParams.data as ProductTypeConfigAddon;
          return x.pricedAddonId;
        },
      },
      // { detailProperty: 'productTypeConfig.productTypeConfigAddons' }
    );

    this.setTitle('Product Catalog for ' + this.account.name);
    this.isPageReady = true;
  }

  // ---------------------------------------------------------------------------------

  onPptGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getPptColDefsAndSortModel();
    // Maybe later
    // this.updatePptMasterDetail(gridOptions);
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    this.updateDatasource();
  }

  getPptColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      {
        field: 'productType.supplier.name',
        headerName: 'Supplier',
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'productType.manufacturer.name',
        headerName: 'Manufacturer',
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'productType.productKey',
        headerName: 'SKU',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'productType.name',
        headerName: 'Name',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'productType.description',
        headerName: 'Description',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: '# Configs',
        filter: 'agNumberColumFilter',
        colId: 'configCount',
        type: 'numericColumn',
        width: 105,
        maxWidth: 105,
        valueGetter: (params) => {
          const ppt = params.data as PricedProductType;
          if (ppt == null) return;
          return ppt.productTypeConfigs.length;
        },
      },
      { field: 'basePrice', headerName: 'Base Price' },
      {
        field: 'productType.leadTimeDays',
        headerName: 'Lead Time (Days)',
        filter: 'agNumberColumnFilter',
      },
    ];
    const sortModel: ISortModel = [
      { colId: 'productType.manufacturer.name', sort: 'asc' },
      { colId: 'productType.name', sort: 'asc' },
      { colId: 'productType.description', sort: 'asc' },
    ];
    return [colDefs, sortModel] as const;
  }

  async onPptRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const ppt = event.data as PricedProductType;
    this.selectedPpt = ppt;
    if (ppt == null) return;
    this.ptcs = _.sortBy(
      this.selectedPpt?.productTypeConfigs || [],
      (ptc) => ptc.name
    );
    this.selectedPtc = _.first(this.ptcs);
    this.onSelectedPtcChange();
    this.updateGridRouteParams(this.pptGridOptions, ppt.id);
  }

  async updateDatasource() {
    if (this.pptGridOptions.api == null) return;
    const returnFn = async (ppts: PricedProductType[]) => {
      const promises: Promise<any>[] = [];

      const newSupplierSet = new Set(
        ppts.map((ppt) => ppt.productType.supplier)
      );
      newSupplierSet.forEach((s) => {
        if (!this.supplierSet.has(s)) {
          this.supplierSet.add(s);
          promises.push(...this.fetchSupplierInfo(s.id));
        }
      });
      await Promise.all(promises);
    };
    const ds = AgFns.buildDatasource(
      () =>
        this.dbQueryService.createPricedProductTypesWithConfigByAccountQuery(
          this.accountId
        ),
      'id',
      { returnFn }
    );
    this.pptGridOptions.api.setServerSideDatasource(ds);
    AgFns.applyGridRouteParams(this.pptGridOptions);
  }

  fetchSupplierInfo(supplierId: string) {
    return [this.dbQueryService.getAddons(supplierId)];
  }

  // Ptc related - PTC dropdown

  async onSelectedPtcChange() {
    const ptc = this.selectedPtc;
    UtilFns.wait(0);
    if (ptc == null) {
      this.ptcps = [];
      return;
    } else {
      this.lookupPrices(ptc);
      this.ptcps = ptc.productTypeConfigProducts;
      this.ptcas = ptc.productTypeConfigAddons;
    }
    const [colDefs, _] = this.getPtcpColDefsAndSortModel(
      ptc.pricedProductType.productType
    );
    this.ptcpGridOptions.api?.setColumnDefs(colDefs);
    AgFns.refreshGrid(this.ptcpGridOptions, this.ptcps);
  }

  lookupPrices(ptc: ProductTypeConfig) {
    ptc.productTypeConfigProducts.forEach((pc) => {
      const fcIdSet = new Set(
        pc.product.productFeatureChoices.map((x) => x.featureChoiceId)
      );
      pc.pricedProductTypeFeatureChoices =
        pc.productTypeConfig.pricedProductType.pricedProductTypeFeatureChoices.filter(
          (pptfc) => fcIdSet.has(pptfc.featureChoiceId)
        );
    });
  }

  // --------------------------------------------------------------------

  onPtcpGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getPtcpColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
  }

  getPtcpColDefsAndSortModel(productType?: ProductType) {
    const colDefs: ColDef[] = [
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        maxWidth: 30,
        minWidth: 30,
      },
      ProxAgFns.getProductSortOrderColDef((x) => x.data.product),
      ...ProductFns.getColDefsForProductType(productType, (params) => {
        return (params.data as ProductTypeConfigProduct).product;
      }),
      { headerName: 'SKU', field: 'product.productKey' },
      {
        headerName: 'Base Price',
        field: 'productTypeConfig.pricedProductType.basePrice',
        editable: false,
        filter: 'agNumberColumnFilter',
        colId: 'basePrice',
      },
      {
        headerName: 'Extra Price',
        filter: 'agNumberColumFilter',
        colId: 'ExtraPrice',
        valueGetter: (params) => {
          const pc = params.data as ProductTypeConfigProduct;
          if (pc == null) return;
          const extraPrice = _.sum(
            pc.pricedProductTypeFeatureChoices?.map((pptfc) => pptfc.price)
          );
          return extraPrice;
        },
        tooltipValueGetter: (params) => {
          params.context = 'Feature Pricing';
          const pc = params.data as ProductTypeConfigProduct;
          if (pc == null) return;
          const lines = pc.pricedProductTypeFeatureChoices?.map((pptfc) => {
            return (
              pptfc.featureChoice.feature.name +
              ' : ' +
              pptfc.featureChoice.validValue +
              ' = ' +
              UtilFns.fmtCurrency(pptfc.price)
            );
          });
          return lines?.join('</br>');
        },
        tooltipComponent: AgInfoTooltip,
      },
      {
        headerName: 'Reqd Add-Ons Price',
        filter: 'agNumberColumFilter',
        colId: 'RAddonsPrice',
        valueGetter: (params) => {
          const ptcp = params.data as ProductTypeConfigProduct;
          if (ptcp == null) return;
          const price = _.sum(
            ptcp.productTypeConfig.productTypeConfigAddons
              .filter((ptcAddon) => !ptcAddon.isOptional)
              .map((ptcAddon) => ptcAddon.pricedAddon.price)
          );
          return price;
        },
      },
      {
        headerName: 'Optional Add-Ons Price',
        filter: 'agNumberColumFilter',
        colId: 'RAddonsPrice',
        valueGetter: (params) => {
          const ptcp = params.data as ProductTypeConfigProduct;
          if (ptcp == null) return;
          const price = _.sum(
            ptcp.productTypeConfig.productTypeConfigAddons
              .filter((ptcAddon) => ptcAddon.isOptional)
              .map((ptcAddon) => ptcAddon.pricedAddon.price)
          );
          return price;
        },
      },
    ];
    const sortModel: ISortModel = [
      { colId: 'productSortOrder', sort: 'asc' },
      // { colId: 'productTypeConfig.pricedProductType.basePrice', sort: 'asc' },
    ];
    AgFns.updateColDefs(colDefs);
    return [colDefs, sortModel] as const;
  }

  onPtcaGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions;
    const [colDefs, sortModel] = this.getPtcaColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
  }

  getPtcaColDefsAndSortModel() {
    const colDefs = [
      {
        field: 'pricedAddon.name',
        headerName: 'Name',
        editable: false,
        filter: 'agTextColumnFilter',
      },
      // {
      //   field: 'pricedAddon.description',
      //   headerName: 'Description',
      //   editable: false,
      //   filter: 'agTextColumnFilter',
      // },
      {
        ...AgFns.createCheckboxProps('Is Optional', 'isOptional', 80,),
        editable: false,
      },
      {
        headerName: 'Price',
        filter: 'agNumberColumFilter',
        colId: 'Price',
        width: 130,
        valueGetter: (params) => {
          const ptcAddon = params.data as ProductTypeConfigAddon;
          if (ptcAddon == null) return;
          const paddon = ptcAddon.pricedAddon;
          const price = paddon.price;
          return price;
        },
      },
    ];
    const sortModel: ISortModel = [
      { colId: 'pricedAddon.name', sort: 'asc' },
    ];
    AgFns.updateColDefs(colDefs);
    return [colDefs, sortModel] as const;
  }

  // ---------------------------------------------
}
