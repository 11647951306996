import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { environment } from '@env';
import { NotificationEvent, NotificationQuery, NotificationTemplate, NotificationType, NotificationTypeEnum } from '@models';
import { DateFns, UtilFns } from '@utils';
import { Entity, EntityError } from 'breeze-client';
import { sanitize } from 'dompurify';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

import { TemplatePreviewDialogComponent, TemplatePreviewDialogParams } from './template-preview-dialog.component';

import { AccountDbQueryService } from '@account';
import * as _ from 'lodash';
import { BaseService, CoreAccountFinderDialog, CoreJobOrderFinderDialog, TemplatesService } from '@core';
import { AccountAdminFinderDialog } from '../account-administrator/account-admin-finder.dialog';


export interface NotificationTemplateDialogParams {
  notificationTemplate: NotificationTemplate;
  allTemplates: NotificationTemplate[];
  contextMenuItems: MenuItem[];
}

@Component({
  selector: 'prox-notification-template-dialog',
  templateUrl: './notification-template-dialog.component.html',
})
export class NotificationTemplateDialogComponent implements OnInit {
  UtilFns = UtilFns;
  notificationTemplate!: NotificationTemplate;
  allTemplates?: NotificationTemplate[];
  
  events: NotificationEvent[] = [];
  allTypes: NotificationType[] = [];
  types: NotificationType[] = [];
  selectedEvent?: NotificationEvent;
  contextMenuItems: MenuItem[] = [];
  isPageReady = false;
  isBeingAdded!: boolean;
  emailAddress = '';
  phone = '';
  startDate = DateFns.fmtDate4(new Date());

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig<NotificationTemplateDialogParams>,
    private dbQueryService: AccountDbQueryService,
    private baseService: BaseService,
    private httpClient: HttpClient,
    private templatesService: TemplatesService
  ) {}

  public static async openDialog(dialogService: DialogService, data?: NotificationTemplateDialogParams, config?: DynamicDialogConfig) {
    const ref = dialogService.open(NotificationTemplateDialogComponent, {
      closable: false, 
      draggable: true,
      width: '800px',
      height: '80vh',
      ...config,
      data: data,
    });
    return firstValueFrom(ref.onClose);
  }

  async ngOnInit() {
    const params = this.config.data;
    UtilFns.assertNonNull(params);

    this.notificationTemplate = params.notificationTemplate;
    this.contextMenuItems = params.contextMenuItems;
    this.allTemplates = params.allTemplates;

    this.events = await this.dbQueryService.getAll(NotificationEvent);
    this.allTypes = await this.dbQueryService.getAll(NotificationType);

    this.onEventChange();
    this.isPageReady = true;
  }

  isDisabled() {
    return this.notificationTemplate.accountId == null;
  }

  canOk() {
    return this.notificationTemplate.notificationEventId != null
      && this.notificationTemplate.notificationTypeId != null
  }

  onOk() {
    this.notificationTemplate.template = sanitize(this.notificationTemplate.template);
    const ok = this.notificationTemplate.entityAspect.validateEntity();
    if (!ok) {
      this.handleBreezeValidationErrors([ this.notificationTemplate]);
      return;
    }
    this.ref.close(true);
  }

  onEventChange() {
    // Filter query types so they are unique for the event
    if (!this.notificationTemplate.notificationEvent) { return; }
    const templatesWithSameEvent = this.allTemplates?.filter(x => x.id != this.notificationTemplate.id &&
      x.notificationEventId == this.notificationTemplate.notificationEventId && x.accountId == this.notificationTemplate.accountId);
    const existingTypes = templatesWithSameEvent?.map(x => x.notificationTypeId);
    this.types = this.allTypes.filter(x => !existingTypes?.includes(x.id));

    // Get the template variables for the event's query
    const queryId = this.notificationTemplate.notificationEvent.notificationQueryId;
    this.templatesService.getNotificationMenuItems(queryId).then(x => { this.contextMenuItems = x; });
  }

  handleBreezeValidationErrors(errEntities: Entity[]) {
    this.baseService.dialogService.validationDialog(this, errEntities, this.navigateToValidationError.bind(this), true);
  }

  navigateToValidationError(ee: EntityError, event: MouseEvent) {
    this.baseService.toastr.warning('Navigation to error not implemented', 'Not yet implemented');
  }

  onCancel() {
    this.notificationTemplate.entityAspect.rejectChanges();
    this.ref.close(false);
  }

  hasChanges() {
    return this.notificationTemplate.entityAspect.entityState.isAddedModifiedOrDeleted();
  }

  canPreview() {
    // const user = this.baseService.authService.getUser();
    // return user?.accountId && this.messageTemplate.template && this.messageTemplate.template.includes('data-type="mention"');
    return this.notificationTemplate.template && this.notificationTemplate.template.includes('{{');
  }

  isEmail() {
    return this.notificationTemplate.notificationTypeId == NotificationTypeEnum.Email;
  }

  isSms() {
    return this.notificationTemplate.notificationTypeId == NotificationTypeEnum.SMS;
  }

  messageType() {
    return NotificationTypeEnum[this.notificationTemplate.notificationTypeId];
  }

  canSendTest() {
    return !!(this.notificationTemplate.notificationEventId 
      && this.notificationTemplate.notificationTypeId !== null
      && this.notificationTemplate.template);
  }

  private async promptForContext(query: NotificationQuery) {
    const ctx: { [key: string]: string | undefined } = {};
    const accountId = this.notificationTemplate.accountId!;

    const parameterNames = (query.parameterNames ?? '').split(',');
    for (const pn of parameterNames.map(pn => pn.trim())) {
      if (pn == "jobOrderId") {
        const jos = await this.baseService.dialogService.createFinder(CoreJobOrderFinderDialog, 
          { accountId: accountId, rowSelection: 'single' });
        ctx[pn] = _.first(jos)?.id;
      }
    
      if ( pn == "accountId") {
        const accts = await this.baseService.dialogService.createFinder(CoreAccountFinderDialog, 
          { rowSelection: 'single' });
        ctx[pn] = _.first(accts)?.id;
      }

      if (pn == "accountAdminId") {
        const allAccountAdmins = await this.dbQueryService.getAccountAdminsByAccountId(accountId);
        const aas = await this.baseService.dialogService.createFinder(AccountAdminFinderDialog, 
          { accountAdmins: allAccountAdmins, rowSelection: 'single' });
        ctx[pn] = _.first(aas)?.id;
      }

      if ( pn == "numberOfDays") {
        const numberOfDays = await this.baseService.dialogService.textpromptDialog("Enter number of days", { text: '0' ?? '', pretext: '' });
        ctx[pn] = numberOfDays;
      }
      
      if ( pn == "startDate") {
        const date = await this.baseService.dialogService.textpromptDialog("Enter start date", { text: this.startDate ?? '', pretext: 'format MM/DD/YYYY' });
        ctx[pn] = date;
      }
    }

    if  (Object.keys(ctx).length !== parameterNames.length) {
      await this.baseService.dialogService.okDialog("Unable to preview", "Not all paramaters could be resolved");
      return null;
    }
 
    return JSON.stringify(ctx);
  }

  async onPreview() {
    const query = this.notificationTemplate.notificationEvent.notificationQuery;
    const json = await this.promptForContext(query);
    if (!json) { return; }

    const params: TemplatePreviewDialogParams = {
      template: this.notificationTemplate.template, 
      isText: this.isSms(),
      queryId: query.id,
      context: json
    };
    // strange pathing...
    TemplatePreviewDialogComponent.openDialog(this.baseService.dialogService.pngDialogService, params)
  }

  async onSendNotification() {
    const query = this.notificationTemplate.notificationEvent.notificationQuery;
    const json = await this.promptForContext(query);
    if (!json) { return; }

    if (this.notificationTemplate.notificationTypeId == NotificationTypeEnum.Email) {
      this.emailAddress = await this.baseService.dialogService.textpromptDialog("Enter email address", { text: this.emailAddress ?? '', posttext: 'Sending takes up to 60 seconds.  Check junk folder.' });
      if (!this.emailAddress) { return; }
    } else if (this.notificationTemplate.notificationTypeId == NotificationTypeEnum.SMS) {
      this.phone = await this.baseService.dialogService.textpromptDialog("Enter phone number", { text: this.phone ?? '', pretext: 'Phone number starting with +1' });
      if (!this.phone) { return; }
    }

    const user = {
      firstName: "Prox",
      lastName: "Tester",
      email: this.emailAddress ?? "",
      cellNumber: this.phone,
      userName: "proxtester"
    }
    const body = {
      notificationEventId: this.notificationTemplate.notificationEventId,
      notificationTypeId: this.notificationTemplate.notificationTypeId,
      user,
      template: this.notificationTemplate.template,
      contextJson: json
    }
    const url = environment.accountUrl + '/SendNotification';

    try {
      const response = await firstValueFrom(this.httpClient.post<{Value: string}>(url, body));

      await this.baseService.dialogService.okDialog('Notification Status', response.Value);
    } catch (ex) {
      await this.baseService.dialogService.okDialog('Notification Status', 'Error sending: ' + UtilFns.getErrorMessage(ex));
    }

  }

}
