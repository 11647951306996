<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div class="card-body flex flex-grow-1 flex-column gap-3">
    <div>
      <h5>Program Information</h5>
    </div>
    <div class="flex gap-3" style="margin-top: -20px;">
      <div class="w-6">
        <prox-field label="Name">
          <input type="text" [disabled]="parent.isReadOnly()" pInputText [(ngModel)]="program.name" [bzModel]="program"
            name="name">
        </prox-field>
      </div>
      <div class="w-3">
        <prox-field label="Program Type">
          <p-dropdown [options]="orderPaidByTypes" optionLabel="name" optionValue="id"
          [disabled]="parent.isReadOnly() || !this.parent.isBeingAdded" 
            [(ngModel)]="program.orderPaidByTypeId" [bzModel]="program" name="orderPaidByTypeId"></p-dropdown>
        </prox-field>
      </div>
    </div>
    <div class="flex gap-3">
      <div class="w-6">
        <prox-field label="Applies to">
          <p-dropdown [disabled]="parent.isReadOnly()" [options]="programApplicabilityTypes" optionLabel="name"
            optionValue="id" (onChange)="onProgramApplicabilityTypeChanged($event.value)"
            [(ngModel)]="program.programApplicabilityTypeId" [bzModel]="program"
            name="programApplicabilityTypeId"></p-dropdown>
        </prox-field>
      </div>
      <div class="w-3">
        <prox-field label="Editing allowed for Admin Group">
          <p-dropdown [disabled]="parent.isReadOnly()" [options]="accountAdminGroups" optionLabel="name"
            optionValue="id" [(ngModel)]="program.accountAdminGroupId" [bzModel]="program"
            name="accountAdminGroupId"></p-dropdown>
        </prox-field>
      </div>
    </div>

    <div class="flex flex-column gap-3">
      <div class="w-12">
        <prox-field label="Description">
          <textarea [disabled]="parent.isReadOnly()" rows="2" cols="30" pInputTextarea [(ngModel)]="program.description"
            [bzModel]="program" name="description"></textarea>
        </prox-field>
      </div>
    </div>

    <div class="flex pb-2">
      <div class="w-4 pr-4">
        <prox-field label="Start Date Type">
          <p-dropdown [options]="programStartDateTypes" optionLabel="name"
            optionValue="id" (onChange)="onProgramStartDateTypeChanged($event.value)"
            [disabled]="parent.isReadOnly() || !this.parent.isBeingAdded" 
            [(ngModel)]="program.programStartDateTypeId" [bzModel]="program" name="programStartDateTypeId">
          </p-dropdown>
        </prox-field>
      </div>
      <div class="w-2 pr-4" *ngIf="program.programStartDateTypeId === ProgramStartDateTypeEnum.CalendarDate">
        <prox-field label="Start Date">
          <p-calendar
            [disabled]="parent.isReadOnly() || !this.parent.isBeingAdded"
            [(ngModel)]="program.startDate" [bzModel]="program" name="startDate"></p-calendar>
        </prox-field>
      </div>
      <div class="w-2 pr-4" *ngIf="program.programStartDateTypeId === ProgramStartDateTypeEnum.CalendarDate">
        <prox-field [ngStyle]="{'color': isExpired ? 'red' : ''}" label="End Date">
          <p-calendar
            [disabled]="(parent.isReadOnly())"
            [(ngModel)]="program.endDate" [bzModel]="program" name="endDate"></p-calendar>
        </prox-field>
      </div>
      <div class="w-3 pr-4" *ngIf="program.programStartDateTypeId === ProgramStartDateTypeEnum.AnniversaryDate">
        <prox-field label="Program Term (in days)">
          <p-inputNumber
            [disabled]="(parent.isReadOnly())"
            [(ngModel)]="program.anniversaryTermDays" [bzModel]="program" name="anniversaryTermDays"></p-inputNumber>
        </prox-field>
      </div>

    </div>
    <div class="flex pb-2">
      <div class="w-3">
        <prox-field label="Shipping Frequency">
          <p-dropdown [disabled]="parent.isReadOnly()" [options]="shipFrequencies" optionLabel="name" optionValue="id"
            [(ngModel)]="program.shipFrequencyId" [bzModel]="program" name="shipFrequencyId"></p-dropdown>
        </prox-field>
      </div>
    </div>
    <div class="flex pb-2">
      <div class="w-4 pr-3">
        <prox-field label="Proximity Account Organization Tree">
          <p-dropdown [disabled]="parent.isReadOnly()" [options]="approvalTrees" optionLabel="name" optionValue="id"
            [(ngModel)]="currentApprovalTreeId" (onChange)="onApprovalTreeChanged($event.value)" [bzModel]="program"
            name="approvalTreeId"></p-dropdown>
        </prox-field>
      </div>
      <div class="w-1 pt-4">
        <p-button styleClass="p-button p-button-sm" [disabled]="parent.hasChanges()"
          (onClick)="parent.onGotoApprovalTree(this.program.approvalTree)" title="View">View</p-button>
      </div>

      <div class="w-4 pr-4">
        <prox-field label="Blanket Purchase Order">
          <p-dropdown [disabled]="parent.isReadOnly()" [options]="blanketPos" optionLabel="purchaseOrder"
            optionValue="id" [(ngModel)]="program.accountBlanketPurchaseOrderId" [bzModel]="program"
            name="accountBlanketPurchaseOrderId"></p-dropdown>
        </prox-field>
      </div>
      <div class="w-1 pt-4">
        <p-button styleClass="p-button p-button-sm" [disabled]="parent.hasChanges()"
          (onClick)="parent.onGotoBlanketPo(this.program.accountBlanketPurchaseOrderId)" title="View">View</p-button>
      </div>
    </div>
    <div class="flex flex-column gap-3 ml-3 mt-4">
      <div class="w-6">
        <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="program.doAllOrdersRequireApproval" [binary]="true"
          [bzModel]="program" name="doAllOrdersRequireApproval" label="Every order requires approval"></p-checkbox>
      </div>
      <div class="w-6">
        <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="program.isPurchaseOrderRequired" [binary]="true"
          [bzModel]="program" name="isPurchaseOrderRequired"
          label="A Purchase Order is required for every order"></p-checkbox>
      </div>
      <div class="w-6">
        <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="program.canShipHome" [binary]="true"
          [bzModel]="program" name="canShipHome" label="Orders may ship to Account User home address"></p-checkbox>
      </div>
      
<!-- 
      <div class="flex flex-column gap-3 ml-3 mt-4">
        <ng-container >
          <p-button [disabled]="parent.isReadOnly()" *ngIf="program.manifestId === null" (click)="createManifest()" styleClass="p-button p-button-sm"
            label="Create Manifest" class="p-button-sm"></p-button>
        </ng-container>
        <ng-container *ngIf="program.manifestId !== null">
          <prox-field  label="Manifest Name">
            <input type="text" [disabled]="parent.isReadOnly()" pInputText [(ngModel)]="manifest!.name" [bzModel]="manifest"
              name="name">
          </prox-field>
          
          <p-checkbox [disabled]="parent.isReadOnly()" [(ngModel)]="manifest.shouldIncludeInvoices" [binary]="true"
            [bzModel]="manifest" name="shouldIncludeInvoices" label="Manifest Should Include Invoices"></p-checkbox>
          
          <div>
            <label>Schedule: {{ getManifestScheduleText() }}</label>
          </div>
          
          <div class="flex gap-3">
            <p-button [disabled]="parent.isReadOnly()" (click)="editManifestSchedule()" styleClass="p-button p-button-sm"
            label="Edit Manifest Schedule" class="p-button-sm"></p-button>
            <p-button [disabled]="parent.isReadOnly()" (click)="deleteManifest()" styleClass="p-button p-button-sm"
            label="Delete Manifest" class="p-button-sm"></p-button>
          </div>
          
        </ng-container>
      </div> -->
    </div>
  </div>
</div>