<div [class]="visible ? 'flex flex-column h-full' : 'hidden'">
  <div>
    <h5>User Groups</h5>
  </div>
  <p-splitter class="flex-grow-1 w-full" layout="vertical" styleClass="h-full" [panelSizes]="[30, 70]">

    <ng-template pTemplate>
      <div class="flex flex-column w-full h-full">
        <div class="flex-grow-1">
          <prox-ag-grid [gridOptions]="pugGridOptions" [rowData]="pugs"></prox-ag-grid>
        </div>
      </div>

    </ng-template>

    <ng-template pTemplate>
      <div class="flex flex-column w-full h-full">
        <div class="pt-2">
          <h5>Users</h5>
        </div>
        <div class="flex flex-column flex-grow-1">
          <div class="flex-grow-1">
            <prox-ag-grid [gridOptions]="auGridOptions" [rowData]="aus"></prox-ag-grid>
          </div>
        </div>

      </div>
    </ng-template>

  </p-splitter>
</div>